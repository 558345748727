'use client';

import { Prose } from '@/components/ArticleAccessibilityMenu/ArticleAccessibilityTextSize';
import EmojiText from '@/components/EmojiText/EmojiText';
import MDXOnClient from '@/components/MDX/MDXOnClient';
import Section from '@/components/Section/Section';
import {
	Guideline,
	GuidelineCategoriesSectionWithGuidelineCategoriesWithGuidelines,
	GuidelineCategoryWithGuidelines,
} from '@/resources/guidelines';
import { Jobs } from '@/resources/jobs';
import { Tags } from '@/resources/tags';
import { Disclosure, Transition } from '@headlessui/react';
import { mdiChevronDown, mdiOpenInNew, mdiStar } from '@mdi/js';
import Icon from '@mdi/react';
import { motion, useReducedMotion } from 'framer-motion';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

export const WCAGLevels = ['A', 'AA', 'AAA'] as const;

export type WCAGLevel = (typeof WCAGLevels)[number];

const GuidelinesList = ({
	guidelineCategorySections,
	searchFilter = '',
	jobsFilter = [],
	tagsFilter = [],
	wcagLevelFilter = [],
}: {
	guidelineCategorySections: GuidelineCategoriesSectionWithGuidelineCategoriesWithGuidelines[];
	searchFilter?: string;
	jobsFilter?: string[];
	tagsFilter?: string[];
	wcagLevelFilter?: WCAGLevel[];
}) => {
	const reduceMotion = useReducedMotion();
	const searchParams = useSearchParams();

	// Filter the guideline categories by WCAG level filter (guidelineCategory.wcagLevel)
	// If no filter is selected, show all
	const filteredGuidelineCategorySectionsByLevels = useMemo(() => {
		// Include levels from searchParams
		const allWCAGLevelsFilter = [
			...wcagLevelFilter,
			...(searchParams
				?.get('levels')
				?.toUpperCase()
				?.split('-')
				?.join(',')
				?.split(',')
				.filter((level) => {
					return WCAGLevels.some((l) => l === level);
				}) ?? []),
		];

		if (allWCAGLevelsFilter.length === 0) {
			return guidelineCategorySections;
		} else {
			return guidelineCategorySections.map(
				(guidelineCategorySection) => ({
					...guidelineCategorySection,
					guidelineCategories:
						guidelineCategorySection.guidelineCategories.filter(
							(guidelineCategory) =>
								allWCAGLevelsFilter.includes(
									guidelineCategory.wcagLevel as WCAGLevel,
								),
						),
				}),
			);
		}
	}, [guidelineCategorySections, wcagLevelFilter, searchParams]);

	// Filter the guidelines by tags filter
	// If no filter is selected, show all
	const filteredGuidelineCategorySectionsByTags = useMemo(() => {
		// Include tags from searchParams
		const allTagsFilter = [
			...tagsFilter,
			...(searchParams
				?.get('tags')
				?.split(',')
				.filter((tag) => {
					return Tags.some((t) => t.id === tag);
				}) ?? []),
		];

		if (allTagsFilter.length === 0) {
			return filteredGuidelineCategorySectionsByLevels;
		} else {
			return filteredGuidelineCategorySectionsByLevels.map(
				(guidelineCategorySection) => ({
					...guidelineCategorySection,
					guidelineCategories:
						guidelineCategorySection.guidelineCategories.filter(
							(guidelineCategory) => {
								return guidelineCategory.tags.data.some((tag) =>
									allTagsFilter.includes(
										tag.attributes.titleSlug,
									),
								);
							},
						),
				}),
			);
		}
	}, [filteredGuidelineCategorySectionsByLevels, tagsFilter, searchParams]);

	// Filter the guidelines by job filter
	// If no filter is selected, show all
	const filteredGuidelineCategorySectionsByJobs = useMemo(() => {
		// Include jobs from searchParams
		const allJobsFilter = [
			...jobsFilter,
			...(searchParams
				?.get('professions')
				?.split(',')
				.filter((tag) => {
					return Jobs.some((j) => j.id === tag);
				}) ?? []),
		];
		if (allJobsFilter.length === 0) {
			return filteredGuidelineCategorySectionsByTags;
		} else {
			return filteredGuidelineCategorySectionsByTags.map(
				(guidelineCategorySection) => ({
					...guidelineCategorySection,
					guidelineCategories:
						guidelineCategorySection.guidelineCategories.filter(
							(guidelineCategory) => {
								return guidelineCategory.guidelines.some(
									(guideline) => {
										return guideline.professions.data.some(
											(job) =>
												allJobsFilter.includes(
													job.attributes.titleSlug,
												),
										);
									},
								);
							},
						),
				}),
			);
		}
	}, [filteredGuidelineCategorySectionsByTags, jobsFilter, searchParams]);

	// Filter the categories and guidelines by search filter
	// If no filter is selected, show all
	const filteredGuidelineCategorySectionsBySearch = useMemo(() => {
		const activeSearchFilter = searchParams?.get('q') ?? searchFilter;

		if (activeSearchFilter.length === 0) {
			return filteredGuidelineCategorySectionsByJobs;
		} else {
			return filteredGuidelineCategorySectionsByJobs.map(
				(guidelineCategorySection) => {
					return {
						...guidelineCategorySection,
						guidelineCategories:
							guidelineCategorySection.guidelineCategories.filter(
								(guidelineCategory) => {
									return guidelineCategory.guidelines.some(
										(guideline) => {
											return `${
												guidelineCategory.wcagNumber
											} ${guidelineCategory.titleLong} ${
												guidelineCategory.description
											} ${guideline.titleLong} ${
												guideline.description
											} ${guideline.professions.data
												.map(
													(job) =>
														job.attributes
															.titleSlug,
												)
												.join(' ')}
											${guideline.guidelineCategory.data.attributes.tags.data
												.map(
													(job) =>
														job.attributes
															.titleSlug,
												)
												.join(' ')}`
												.toLowerCase()
												.includes(
													activeSearchFilter.toLowerCase(),
												);
										},
									);
								},
							),
					};
				},
			);
		}
	}, [filteredGuidelineCategorySectionsByJobs, searchFilter, searchParams]);

	const totalCategoryCount = useMemo(() => {
		return guidelineCategorySections.reduce(
			(count, guidelineCategorySection) => {
				return (
					count + guidelineCategorySection.guidelineCategories.length
				);
			},
			0,
		);
	}, [guidelineCategorySections]);

	const visibleCategoryCount = useMemo(() => {
		return filteredGuidelineCategorySectionsBySearch.reduce(
			(count, guidelineCategorySection) => {
				return (
					count + guidelineCategorySection.guidelineCategories.length
				);
			},
			0,
		);
	}, [filteredGuidelineCategorySectionsBySearch]);

	return (
		<motion.section
			aria-label="Richtlijnen"
			initial={{
				opacity: 0,
				y: reduceMotion ? 0 : '4rem',
			}}
			animate={{
				opacity: 1,
				y: 0,
				transition: {
					delay: 0.2,
				},
			}}
		>
			{visibleCategoryCount === 0 && (
				<div
					className="mb-16 text-center text-slate-800 dark:text-slate-100"
					role="status"
				>
					<p>
						Er zijn geen richtlijnen gevonden die voldoen aan de
						zoekopdracht.
					</p>
				</div>
			)}
			{(!!jobsFilter.length || !!tagsFilter || !!searchFilter.length) && (
				<div
					className="mb-4 text-center text-slate-800 dark:text-slate-100"
					role="status"
				>
					<hr className="mb-4" />
					{!!searchFilter && (
						<p className="text-center text-lg font-semibold">
							&apos;{searchFilter}&apos;
						</p>
					)}
					<p>
						{visibleCategoryCount} van de {totalCategoryCount}{' '}
						categorieën weergegeven
					</p>
				</div>
			)}
			<div className="grid gap-8">
				{filteredGuidelineCategorySectionsBySearch.map(
					(guidelineCategorySection) => (
						<CategorySection
							key={guidelineCategorySection.wcagNumber}
							section={guidelineCategorySection}
							jobsFilter={jobsFilter}
						/>
					),
				)}
			</div>
		</motion.section>
	);
};

const CategorySection = ({
	section,
	jobsFilter,
}: {
	section: GuidelineCategoriesSectionWithGuidelineCategoriesWithGuidelines;
	jobsFilter: string[];
}) => {
	return (
		<Section
			aria-label={section.titleLong}
			className="bg-primary-100/50 dark:bg-slate-950/50"
		>
			<h2 className="flex items-center justify-center gap-4 p-4 pb-0 text-3xl text-primary-900 dark:text-primary-200">
				<div aria-hidden className="shrink-0 scale-110 text-center">
					<EmojiText>{section.emoji}</EmojiText>
				</div>
				{section.titleLong}
			</h2>
			<div className="grid gap-4 pt-4 md:p-4">
				{section.guidelineCategories.length > 0 ? (
					section.guidelineCategories.map(
						(guidelineCategoryWithGuidelines) => (
							<GuidelineCategoryView
								key={guidelineCategoryWithGuidelines.wcagNumber}
								guidelineCategoryWithGuidelines={
									guidelineCategoryWithGuidelines
								}
								jobsFilter={jobsFilter}
							/>
						),
					)
				) : (
					<p className="p-4 text-center">
						Geen richtlijnen gevonden die voldoen aan de filters.
					</p>
				)}
			</div>
		</Section>
	);
};

const GuidelineCategoryView = ({
	guidelineCategoryWithGuidelines,
	jobsFilter,
}: {
	guidelineCategoryWithGuidelines: GuidelineCategoryWithGuidelines;
	jobsFilter: string[];
}) => {
	const wcagNumberColors = useMemo(
		() =>
			guidelineCategoryWithGuidelines.wcagLevel === 'AAA'
				? 'bg-quaternary-600 dark:bg-quaternary-900 text-white'
				: 'bg-primary-600 dark:bg-primary-900 text-white',
		[guidelineCategoryWithGuidelines.wcagLevel],
	);

	const wcagLevelColors = useMemo(
		() =>
			guidelineCategoryWithGuidelines.wcagLevel === 'AAA'
				? 'bg-quaternary-100 dark:bg-quaternary-950 text-quaternary-700 dark:text-quaternary-100'
				: 'bg-primary-100 dark:bg-primary-950 text-primary-700 dark:text-primary-100',
		[guidelineCategoryWithGuidelines.wcagLevel],
	);

	const wcagLevelColors2 = useMemo(
		() =>
			guidelineCategoryWithGuidelines.wcagLevel === 'AAA'
				? 'bg-quaternary-200 dark:bg-quaternary-900 dark:text-white'
				: 'bg-primary-200 dark:bg-primary-900 dark:text-white',
		[guidelineCategoryWithGuidelines.wcagLevel],
	);

	return (
		<Disclosure>
			{({ open }) => (
				<div
					className={`shadow-border-bottom-sm bg-white dark:bg-slate-900 ${
						open
							? 'shadow-lg dark:border-primary-700'
							: 'shadow-sm hover:shadow-md dark:border-slate-700'
					} rounded-xl transition-all duration-300 after:rounded-xl`}
				>
					<h3>
						<Disclosure.Button
							className={`flex w-full flex-col items-center text-lg md:flex-row md:pe-2 ${
								open ? 'rounded-t-xl shadow-md' : 'rounded-xl'
							} focus:bg-primary/25`}
						>
							<div
								className={`relative shrink-0 grow-0 self-stretch md:w-20 ${wcagNumberColors} ${
									open
										? 'max-md:rounded-t-xl md:rounded-ss-xl'
										: 'rounded-t-xl md:rounded-e-none md:rounded-s-xl'
								} p-4 text-start md:px-0 md:text-center`}
							>
								{guidelineCategoryWithGuidelines.wcagNumber}
							</div>
							<div className="w-full p-4 text-start">
								<span className="sr-only">Niveau </span>
								{guidelineCategoryWithGuidelines.titleLong}
							</div>
							{guidelineCategoryWithGuidelines.wcagLevel ===
								'AAA' && (
								<div
									className={`shrink-0 md:ml-auto ${wcagLevelColors} rounded-full p-1.5`}
									title="Optioneel"
								>
									<Icon path={mdiStar} size={0.85} />
								</div>
							)}
							<div aria-hidden className="px-2">
								<Icon
									path={mdiChevronDown}
									size={1}
									className={`${
										open ? '-scale-y-100' : ''
									} transition-all`}
								/>
							</div>
						</Disclosure.Button>
					</h3>
					<Transition
						enter="transition duration-100 ease-out"
						enterFrom="motion-safe:scale-95 opacity-0"
						enterTo="motion-safe:scale-100 opacity-100"
						leave="transition duration-75 ease-out"
						leaveFrom="motion-safe:scale-100 opacity-100"
						leaveTo="motion-safe:scale-95 opacity-0"
					>
						<Disclosure.Panel className="rounded-b-xl bg-slate-100 dark:bg-slate-950">
							{guidelineCategoryWithGuidelines.wcagLevel ===
								'AAA' && (
								<div className="-mb-1 bg-quaternary-100 p-4 text-sm font-semibold text-quaternary-900 shadow-inner dark:bg-quaternary-900 dark:text-quaternary-100">
									Deze richtlijn hoort bij niveau AAA en is
									dus niet verplicht, maar draagt wel extra
									bij aan de toegankelijkheid van je
									productie.
								</div>
							)}
							<div
								className={`flex gap-2 p-4 pb-0 font-sans text-sm font-bold sm:flex-row ${guidelineCategoryWithGuidelines.tags.data.length === 1 ? 'flex-row' : 'flex-col'}`}
							>
								<p
									className={`h-fit w-fit ${wcagLevelColors2} text-nowrap rounded-full px-1.5`}
								>
									Niveau{' '}
									{guidelineCategoryWithGuidelines.wcagLevel}
								</p>

								{guidelineCategoryWithGuidelines.tags.data
									.length !== 0 && (
									<div className="row flex flex-wrap gap-2">
										<span>Tags:</span>
										<ul
											aria-label="Tags"
											className="row flex flex-wrap gap-2"
										>
											{guidelineCategoryWithGuidelines.tags.data.map(
												(tag) => {
													const t =
														Tags.find(
															(t) =>
																t.id ===
																tag.attributes
																	.titleSlug,
														) ?? Tags[0];
													return (
														<li
															key={
																tag.attributes
																	.titleShort
															}
															title={
																tag.attributes
																	.titleShort
															}
															className={`rounded-full bg-opacity-75 px-1.5 ${t.colors.bg.light} ${t.colors.text.dark}`}
														>
															{
																tag.attributes
																	.titleShort
															}
														</li>
													);
												},
											)}
										</ul>
									</div>
								)}
							</div>

							{guidelineCategoryWithGuidelines.description && (
								<Prose maxTextSize="xs" className="p-4 pb-0">
									<MDXOnClient>
										{
											guidelineCategoryWithGuidelines.description
										}
									</MDXOnClient>
								</Prose>
							)}

							<ul
								aria-label="Bijbehorende richtlijnen"
								className="grid gap-2 pt-2"
							>
								{guidelineCategoryWithGuidelines.guidelines.map(
									(guideline) => (
										<GuidelineView
											key={guideline.uid}
											guideline={guideline}
											jobsFilter={jobsFilter}
										/>
									),
								)}
							</ul>

							{!!guidelineCategoryWithGuidelines.wcagUrl && (
								<div className="p-2 pt-1">
									<WCAGLink
										wcagUrl={
											guidelineCategoryWithGuidelines.wcagUrl
										}
										title={
											guidelineCategoryWithGuidelines.titleLong
										}
									/>
								</div>
							)}
						</Disclosure.Panel>
					</Transition>
				</div>
			)}
		</Disclosure>
	);
};

export const WCAGLink = ({
	wcagUrl,
	title,
	noMargin,
}: {
	wcagUrl: string;
	title: string;
	noMargin?: boolean;
}) => (
	<Prose maxTextSize="xs">
		<Link
			href={wcagUrl}
			className={noMargin ? undefined : 'block p-2'}
			target="_blank"
			rel="noopener noreferrer"
		>
			<div className="flex items-center gap-1 font-semibold text-primary-800 dark:text-primary-200">
				WCAG over &apos;
				{title}
				&apos;
				<Icon path={mdiOpenInNew} size={0.75} className="shrink-0" />
			</div>
		</Link>
	</Prose>
);

export const GuidelineView = ({
	as = 'li',
	guideline,
	jobsFilter,
	defaultOpen,
	className,
}: {
	as?: 'li' | 'div';
	guideline: Guideline;
	jobsFilter: string[];
	defaultOpen?: boolean;
	className?: string;
}) => {
	const Comp = as;

	const jobsFilterEnabledAndNotInJobsFiler =
		jobsFilter.length > 0 &&
		!guideline.professions.data.some((job) =>
			jobsFilter.includes(job.attributes.titleSlug),
		);

	if (!guideline.description) {
		return (
			<Comp className={className}>
				<h4
					className={`shadow-border-bottom-sm flex flex-col gap-1 border-l-4 border-l-primary bg-white p-4 font-brand text-base font-bold dark:bg-slate-800 ${
						jobsFilterEnabledAndNotInJobsFiler ? 'opacity-30' : ''
					}`}
				>
					{guideline.titleLong}
					<GuidelineProfessionsList
						professions={guideline.professions.data.map(
							(job) => job.attributes.titleSlug,
						)}
						jobsFilter={jobsFilter}
					/>
				</h4>
			</Comp>
		);
	}

	return (
		<Comp className={className}>
			<Disclosure defaultOpen={defaultOpen}>
				{({ open }) => (
					<div
						className={`shadow-border-bottom-sm bg-white dark:bg-slate-800 ${
							open ? 'shadow-md ring-2 ring-primary-200' : ''
						} ${
							!open && jobsFilterEnabledAndNotInJobsFiler
								? 'opacity-30'
								: ''
						} border-l-4 border-l-primary transition-all`}
						aria-hidden={
							!open && jobsFilterEnabledAndNotInJobsFiler
						}
					>
						<h4>
							<Disclosure.Button
								className={`flex w-full items-center justify-between overflow-hidden p-4 text-left font-brand text-base font-bold`}
								tabIndex={
									!open && jobsFilterEnabledAndNotInJobsFiler
										? -1
										: 0
								}
							>
								<div className="flex grow flex-col gap-1">
									{guideline.titleLong}
									<GuidelineProfessionsList
										professions={guideline.professions.data.map(
											(job) => job.attributes.titleSlug,
										)}
										jobsFilter={jobsFilter}
									/>
								</div>
								<div
									aria-hidden
									className="ms-2 size-6 shrink-0 rounded-full text-base"
								>
									<Icon
										path={mdiChevronDown}
										size={1}
										className={`${
											open ? '-scale-y-100' : ''
										} transition-all`}
									/>
								</div>
							</Disclosure.Button>
						</h4>
						<Transition
							enter="transition duration-100 ease-out"
							enterFrom="motion-safe:scale-95 opacity-0"
							enterTo="motion-safe:scale-100 opacity-100"
							leave="transition duration-75 ease-out"
							leaveFrom="motion-safe:scale-100 opacity-100"
							leaveTo="motion-safe:scale-95 opacity-0"
						>
							<Disclosure.Panel>
								<Prose maxTextSize="xs" className="px-4 pb-4">
									<MDXOnClient>
										{guideline.description ??
											'Geen beschrijving'}
									</MDXOnClient>
								</Prose>
							</Disclosure.Panel>
						</Transition>
					</div>
				)}
			</Disclosure>
		</Comp>
	);
};

export const GuidelineProfessionsList = ({
	professions,
	jobsFilter,
	className,
}: {
	professions: string[];
	jobsFilter: string[];
	className?: string;
}) => {
	const sortedProfessions = useMemo(() => {
		return (
			professions
				// Only show professions that are in the JOBS object
				.filter((profession) =>
					Jobs.some((job) => job.id === profession),
				)
				// Sort by order in JOBS object
				.sort((a, b) => {
					const jobA = Jobs.findIndex((job) => job.id === a);
					const jobB = Jobs.findIndex((job) => job.id === b);

					if (jobA > -1 && jobB > -1) {
						return jobA - jobB;
					} else if (jobA > -1) {
						return -1;
					} else if (jobB) {
						return 1;
					}
					return 0;
				})
				// Sort by filter
				.sort((a, b) => {
					if (jobsFilter.includes(a)) {
						return -1;
					} else if (jobsFilter.includes(b)) {
						return 1;
					} else {
						return 0;
					}
				})
		);
	}, [professions, jobsFilter]);

	return (
		<ul className={twMerge('inline-flex w-fit flex-wrap gap-1', className)}>
			{sortedProfessions.map((profession) => {
				const job =
					Jobs.find((job) => job.id === profession) ?? Jobs[0];

				return (
					<li
						key={profession}
						title={job.title}
						className={`relative block max-w-16 px-1.5 text-xs ${job.colors.bg.light} ${job.colors.text.dark} overflow-hidden rounded-full bg-opacity-75 font-sans font-bold`}
					>
						<span className="line-clamp-1">{job.shortTitle}</span>
						<div
							className={`absolute inset-0 ml-10 mr-1 h-full bg-gradient-to-l ${job.colors.bg.fadeGradientLight} z-1`}
						/>
						<div
							className={`absolute -right-1 top-0 h-full w-2 ${job.colors.bg.light} z-1`}
						/>
					</li>
				);
			})}
		</ul>
	);
};

export default GuidelinesList;

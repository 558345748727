export interface Job {
	id: string;
	title: string;
	shortTitle: string;
	emoji: string;
	url: string;
	colors: {
		bg: JobColorSet & {
			fadeGradientLight?: string;
		};
		text: JobColorSet;
	};
	isHidden?: boolean;
}

interface JobColorSet {
	light: string;
	dark: string;
}

export const Jobs: Job[] = [
	{
		id: 'projectleider',
		title: 'Projectleider',
		shortTitle: 'Projectleider',
		emoji: '🙋‍♀️',
		url: '/projectleider',
		colors: {
			bg: {
				light: 'bg-red-200',
				dark: 'bg-red-700',
				fadeGradientLight: 'from-red-200 to-red-200/0',
			},
			text: {
				light: 'text-red-200',
				dark: 'text-red-950',
			},
		},
	},
	{
		id: 'ontwikkelaar',
		title: 'Ontwikkelaar',
		shortTitle: 'Ontwikkelaar',
		emoji: '💻',
		url: '/ontwikkelaar',
		colors: {
			bg: {
				light: 'bg-teal-200',
				dark: 'bg-teal-700',
				fadeGradientLight: 'from-teal-200 to-teal-200/0',
			},
			text: {
				light: 'text-teal-200',
				dark: 'text-teal-950',
			},
		},
	},
	{
		id: 'ux-ontwerper',
		title: 'UX-ontwerper',
		shortTitle: 'UX',
		emoji: '🌈',
		url: '/ux-ontwerper',
		colors: {
			bg: {
				light: 'bg-yellow-200',
				dark: 'bg-yellow-700',
				fadeGradientLight: 'from-yellow-200 to-yellow-200/0',
			},
			text: {
				light: 'text-yellow-200',
				dark: 'text-yellow-950',
			},
		},
	},
	{
		id: 'grafisch-ontwerper',
		title: 'Grafisch ontwerper',
		shortTitle: 'Grafisch',
		emoji: '✨',
		url: '/grafisch-ontwerper',
		colors: {
			bg: {
				light: 'bg-orange-200',
				dark: 'bg-orange-700',
				fadeGradientLight: 'from-orange-200 to-orange-200/0',
			},
			text: {
				light: 'text-orange-200',
				dark: 'text-orange-950',
			},
		},
	},
	{
		id: 'redacteur',
		title: 'Redacteur',
		shortTitle: 'Redacteur',
		emoji: '📝',
		url: '/redacteur',
		colors: {
			bg: {
				light: 'bg-blue-200',
				dark: 'bg-blue-700',
				fadeGradientLight: 'from-blue-200 to-blue-200/0',
			},
			text: {
				light: 'text-blue-200',
				dark: 'text-blue-950',
			},
		},
	},
	{
		id: 'innovateur',
		title: 'Innovateur',
		shortTitle: 'Innovateur',
		emoji: '🚀',
		url: '/innovateur',
		colors: {
			bg: {
				light: 'bg-purple-200',
				dark: 'bg-purple-700',
				fadeGradientLight: 'from-purple-200 to-purple-200/0',
			},
			text: {
				light: 'text-purple-200',
				dark: 'text-purple-950',
			},
		},
	},
	{
		id: 'tester',
		title: 'Tester',
		shortTitle: 'Tester',
		emoji: '🧪',
		url: '/tester',
		colors: {
			bg: {
				light: 'bg-lime-200',
				dark: 'bg-lime-700',
				fadeGradientLight: 'from-lime-200 to-lime-200/0',
			},
			text: {
				light: 'text-lime-200',
				dark: 'text-lime-950',
			},
		},
		isHidden: true,
	},
] as const;

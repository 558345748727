export interface Tag {
	id: string;
	title: string;
	shortTitle: string;
	emoji: string;
	url: string;
	colors: {
		bg: TagColorSet & {
			fadeGradientLight?: string;
		};
		text: TagColorSet;
	};
	isHidden?: boolean;
}

interface TagColorSet {
	light: string;
	dark: string;
}

export const Tags: Tag[] = [
	{
		id: 'tekst',
		title: 'Tekst',
		shortTitle: 'Tekst',
		emoji: '📃',
		url: '/tekst',
		colors: {
			bg: {
				light: 'bg-orange-200',
				dark: 'bg-orange-700',
				fadeGradientLight: 'from-orange-200 to-orange-200/0',
			},
			text: {
				light: 'text-orange-200',
				dark: 'text-orange-950',
			},
		},
	},
	{
		id: 'stilstaand-beeld',
		title: 'Stilstaand beeld',
		shortTitle: 'Stilstaand beeld',
		emoji: '🖼️',
		url: '/stilstaand-beeld',
		colors: {
			bg: {
				light: 'bg-red-200',
				dark: 'bg-red-700',
				fadeGradientLight: 'from-red-200 to-red-200/0',
			},
			text: {
				light: 'text-red-200',
				dark: 'text-red-950',
			},
		},
	},
	{
		id: 'bewegend-beeld',
		title: 'Bewegend beeld',
		shortTitle: 'Bewegend beeld',
		emoji: '📽️',
		url: '/bewegend-beeld',
		colors: {
			bg: {
				light: 'bg-teal-200',
				dark: 'bg-teal-700',
				fadeGradientLight: 'from-teal-200 to-teal-200/0',
			},
			text: {
				light: 'text-teal-200',
				dark: 'text-teal-950',
			},
		},
	},
	{
		id: 'audio',
		title: 'Audio',
		shortTitle: 'Audio',
		emoji: '🎧',
		url: '/audio',
		colors: {
			bg: {
				light: 'bg-yellow-200',
				dark: 'bg-yellow-700',
				fadeGradientLight: 'from-yellow-200 to-yellow-200/0',
			},
			text: {
				light: 'text-yellow-200',
				dark: 'text-yellow-950',
			},
		},
	},
] as const;
